.projecItemsCotainer {
   padding: 0px 0px 40px 32px;
}


@media (max-width: 991px) {
   .projecItemsCotainer {
      padding: 16px 16px 40px 16px;
   }
}

@media (max-width: 575px) {
   .projecItemsCotainer {
      padding: 16px 0px 40px 0px;
   }
}

.project-order-save-button {
   /* position: fixed; */
   bottom: 0;
   right: 0;
   /* margin: 30px 40px; */
   padding: 8px 20px;
   color: white;
   background-color: #28A744;
   border: none;
   border-radius: 8px;
   transition: 0.2s ease;
   /* height: 50px; */
   margin-left: 12px;
}

.project-order-save-button:hover {
   background-color: #208135;
}

.project-order-save-button:disabled {
   background-color: #919191;
}

.project-order-cancel-button {
   bottom: 0;
   right: 0;
   padding: 8px 20px;
   color: white;
   background-color: #DC3644;
   border: none;
   border-radius: 8px;
   transition: 0.2s ease;
   margin-left: 12px;
}

.project-order-cancel-button:hover {
   background-color: #b42c38;
}

.project-order-cancel-button:disabled {
   background-color: #919191;
}

.reorder-toolbar {
   border-top: 1px solid gray;
   background-color: rgba(255, 255, 255, 0.815);
   position: fixed;
   display: flex;
   justify-content: space-between;
   align-items: center;
   bottom: 0;
   left: 0;
   text-align: right;
   height: 80px;
   width: 100%;
}

.reorder-toolbar p {
   margin: 0;
   margin-left: 32px;
}
