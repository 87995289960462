
div.project-item {
    padding-top: 20px;
    max-width: 1200px;
    padding-bottom: 4rem;
}

.p-main-img {
    display: block;
    margin: auto;
    background-repeat:  no-repeat;
    background-position: center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    width: 100%;
    height: calc(40vw + 10vh);
    max-height: 720px;
}


.p-info-box {
    padding: 30px 15px;
}

.p-info-box .title h3 {
    margin: 0;
}
.p-info-box .title {
    display:inline-block;
    margin-bottom: 8px;
}

.subtitle {
    font-weight: lighter;
    font-size: 1.15rem;
    padding: 8px 0;
    margin-bottom: 16px;
    font-weight: 100;
}

.subtitle div,
.subtitle p,
.subtitle p b {
    margin: 0;
    padding: 0;
    display:inline-block;
}

.subtitle:not(div) p,
.subtitle p b {
    white-space: pre;
}



.subtitle p b {
    color: #8d0000;
    font-size: 1.2rem;
}

p.description {
    padding-top: 20px;
}


button.tallenna-btn,
button.takaisin-btn {
    width: 128px;
    margin: 4px;
}


@media (max-width: 992px) {

    .p-info-box {
        padding: 30px 12px;
    }

}

@media (max-width: 575px) {

    .project-item {
        padding: 4px;
    }

    .p-img-container {
        padding: 4px;
    }
}
