.button-more {
    display: block;
    margin: auto;
    border: solid 2px #930000;
    color: #930000;
    text-transform: uppercase;
    background: none;
    font-size: 1rem;
    width: 150px;
    height: 40px;
    padding-top: 6px;
    border-radius: 1px;
    text-align: center;
    letter-spacing: 0.06rem;
    text-decoration: none;
}

.button-more-bigger {
    padding-top: 10px;
    width: 200px;
    height: 46px;
}

.button-more:focus {
    text-decoration: none;
    color: #930000;
}

.button-more:hover {
    cursor: pointer;
    background-color: #930000;
    color: #fff;
    text-decoration: none;
}



div.moreButtonBox {
    padding-top: 10px;
    width: 100%;
}