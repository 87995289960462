.side-menu-container {
    padding-right: 0;
    padding-left: 0;
    min-width: 320px;
}

@media (max-width: 1300px) {
    .side-menu-container {
        min-width: 260px;
    }
}


.topCategoryTitle {
    padding: 80px 0px 0px 32px;
 }