section.about-us {
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
}

.container-about-us {
    min-height: 100vh;
    padding: 50px;
    max-width: 1800px;
    padding-top: 0;
    padding-bottom: 0;
}

.about-row {
    min-height: 100vh;
}

.aboutImageContainer {
    padding: 5rem;
    padding-left: 3.5rem;
    padding-right: 0;
}

.aboutUsImageBox {
    background-position: center;
    background-size: cover;
    height: 800px;
    max-height: 70vh;
    
}

.about-text-container {
    
    max-width: 650px;
    padding: 1rem;
    padding-top: 200px;
    padding-bottom: 3rem;
}



.center-this-col-lg {
    display: block;
    margin: auto;
}


.more-button-container {
    width: 150px;
}




@media (max-width: 1800px) {
    
    .aboutImageContainer {
        padding-left: 2rem;
    }

}


@media (max-width: 1200px) {
    
    .aboutUsImageBox {
        height: 50vh;
    }
}


@media (max-width: 992px) {
    
    .container-about-us {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    
    .about-text-container {
        padding: 2rem;
        max-width: none;
    }
    
    .aboutImageContainer {
        padding: 0;
    }
    
    .center-this-col-lg {
        margin-bottom: 0;
        margin-top: auto;
    }
    
}


@media (max-width: 600px) {
    
    .container-about-us {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .about-text-container {
        padding: 2rem 5px;
    }
    
}