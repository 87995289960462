
div.project-list-item {
    padding: 12px 32px;
    width: 100%;
    max-width: 1000px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background: rgba(254,254,253, 1);
    border-radius: 3px;
    margin: 26px 0px;
}

.p-main-icon {
    width: 192px;
    height: 192px;
    background-repeat:  no-repeat;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.p-list-item-info-box {
    padding-left: 32px;
    padding-top: 24px;
}

button.edit-project,
button.delete-project {
    display: inline-block;
    width: 128px;
    margin: 4px;    
}

button.new-project {
    width: 100%;
    margin: auto;
}

@media (max-width: 991px) {
    .p-main-icon {
        width: 128px;
        height: 128px;
    }
}


@media (max-width: 767px) {
    button.edit-project,
    button.delete-project {
        width: 100px;
    }

    .p-list-item-info-box {
        padding-top: 0px;
    }
}


@media (max-width: 575px) {

    .p-list-item-info-box {
        margin: auto;
    }

    .p-main-icon {
        margin: auto;
        margin-bottom: 32px;
        width: 80%;
        height: 128px;
    }

    div.project-list-item {
        padding: 12px;
    }

}


