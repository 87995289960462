.contact-header {
    background-position: center;
    background-size: cover;
    height: 75vh;
    width: auto;
    position: relative;
}

.header-contact-info-box {
    padding: 18px;
    padding-left: 40px;
    padding-right: 40px;
    background: #fff;
    max-width: 50%;
    position: absolute;
    bottom: 15vh;
    left: 0;
}

@media (min-width: 993px) {
    
    .header-contact-info-box {
        display: none;
    }
    
    .slide-from-left {
        -webkit-animation-name: example;
        -webkit-animation-duration: 1s;
        animation-name: example;
        animation-duration: 1s;
        display: block;
    }
}


.header-contact-info-box p {
    line-height: 22px;
    font-size: 20px;
    font-weight: 500;
}




/* Safari 4.0 - 8.0 */
@-webkit-keyframes example {
    0%   {left: -50%}
    100% {left:0px}
}

/* Standard syntax */
@keyframes example {
    0%   {left: -50%}
    100% {left:0px}
}





@media (max-width: 992px) {
    
    .header-contact-info-box {
        width: 100%;
        max-width: 100%;
        bottom: 0;
        left: 0;
        padding: 20px;
        text-align: center;
        padding-top: 4rem;
        padding-bottom: 0;
    }

    .contact-header {
        height: calc(50vh + 250px);
    }
    
    .header-contact-info-box h2 {
        padding-bottom: 1rem;
    }
    
}

@media (max-width: 576px) {
    
    .header-contact-info-box h2 {
        font-size: 2rem;
    }
    
    .header-contact-info-box p {
        font-size: 1rem;
    }
    
}

@media (max-width: 350px) {
    
    .header-contact-info-box h2 {
        font-size: 1.8rem;
    }
    
    
}