.dialog--background {
   z-index: 999;
   position: fixed;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.39);
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.dialog--box {
   background-color: white;
   padding: 32px;
   border-radius: 8px;
}

.dialog--box--buttons {
   display: flex;
   justify-content: flex-end;
}

.dialog--button {
   margin-left: 8px;
   outline: none;
   border: none;
   border-radius: 0.25rem;
   padding: 8px 20px;
   color: white;
   transition: 0.2s ease;
}

.dialog--button__red {
   background-color: #DC3644;
}

.dialog--button__red:hover {
   background-color: #b82d39;
}

.dialog--button__green {
   background-color: #28A744;
}

.dialog--button__green:hover {
   background-color: #1f8535;
}

.dialog--button:disabled {
   background-color: rgb(168, 168, 168);
}
