.contact-us-section {
    background-position: center;
    background-size: cover;
    min-height: 30vh;
}


.section-contact-text-box {
    text-align: center;
/*    font-size: 2.5rem;*/
    padding: 1rem;
    padding-top:30px;
    padding-bottom: 2.5rem;
}