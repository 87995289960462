@import url(https://fonts.googleapis.com/css?family=Lato:300,400);
html {
    overflow-x: hidden;
}

body {
    font-family: 'Lato', sans-serif;
    margin: 0;
    padding: 0;
    word-wrap: break-word;
}

section {
    padding: 6rem 0rem;
}


section.sectionGray {
    background-color: #ededed;
}

section.no-padding {
    padding: 0;
/*    background-color: #606060;*/
}

.container-dark {
    background-color: #404040;
    color: white;
}

.container-light-gray {
    background-color: #fafafa;
}

.inner-container {
    padding: 30px 16px;
}


.max-width-container {
    max-width: 1300px;
}


/*INTRO SECTION*/

.jumbotron-fluid {
    text-align: center;
    background: none;
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 0;
}

hr.shortLine {
    width: 100px;
    color: black;
    background-color: black;
    opacity: 1;
    height: 1px;
    border: none;
    padding: 0;
    margin-top: 20px;
}



/*Section Projects*/


.center-this {
    display: block;
    margin: auto;
}

.v-h-center {
    display: flex;
    align-items: center;
    justify-content: center;

}

.text-align-center {
    text-align: center;
}



.PageTitle h1 {
    font-size: 4rem;
    text-transform: uppercase;
}


@media (max-width: 768px) {
    .PageTitle h1 {
        font-size: 3rem;
    }
    
    .inner-container {
        padding: 20px 0px;
    }
}

@media (max-width: 576px) {
    .PageTitle h1 {
        font-size: 2.5rem;
    }
}

@media (max-width: 370px) {
    .PageTitle h1 {
        font-size: 2.1rem;
    }
}



#red {
    background: red;
}

.appearOnScroll {
    opacity: 0;
}
footer {
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: #2a2a2a;
    color: white;
}

div.footer-box {
    padding-bottom: 40px;
}


footer ul {
    list-style: none;
    padding-left: 0;
}

.footer-inner-container {
    padding: 130px;
    padding-bottom: 20px;
    max-width: 1800px;
}

footer .footer-link {
    color: inherit;
    text-decoration: none;
}

footer .footer-link:hover {
    color: inherit;
    text-decoration: underline;
}

footer .footer-link:visited {
    color: inherit;
    text-decoration: none;
}

.logo-box {
    display: block;
    margin: auto;
    padding: 20px 40px;;
}

.logo-box img {
    max-width: 100%;
    display: block;
    margin: auto;
}

footer div.logo-container {
    max-width: 280px;
    padding-top: 20px;
}


.bromeco-logo-img {
    width: 104px;
    transition: all 0.5s;
    opacity: 0.3;
}

.bromeco-logo-img:hover, bromeco-logo-img:focus {
    -webkit-transform: scale3d(1.1, 1.1, 1);
            transform: scale3d(1.1, 1.1, 1);
    cursor: pointer;
    opacity: 0.5;
}

.logo-row {
    padding: 26px;
    padding-bottom: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bromeco-logo-row {
    padding: 26px;
    padding-top: 20px;
    padding-bottom: 34px;
}

.bromeco-logo-row > a {
    margin-left: auto;
    margin-right: auto;
}

.row-copyright {
  padding-top: 20px;
  font-weight: 300;
  color: #696969;
  max-width: 100vw;
  padding-left: 12px;
  padding-right: 12px;
}

.row-logo {
    margin: 20px 50px;
    max-width: 180px;
    /* height: auto; */
    max-height: 190px;

}

@media (max-width: 1200px) {
    .footer-inner-container {
        padding: 80px;
        padding-bottom: 60px;
    }
}

@media (max-width: 576px) {
    .footer-inner-container {
        padding: 50px;
        padding-top: 80px;
        padding-bottom: 15px;
    }
}
/* Alignment styles for images, videos and iframes in editable regions */

/* Center (default) */
[data-editable] iframe,
[data-editable] image,
[data-editable] [data-ce-tag=img],
[data-editable] img,
[data-editable] video {
    clear: both;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

/* Left align */
[data-editable] .align-left {
    clear: initial;
    float: left;
    margin-right: 0.5em;
}

/* Right align */
[data-editable].align-right {
    clear: initial;
    float: right;
    margin-left: 0.5em;
}

/* Alignment styles for text in editable regions */
[data-editable] .text-center {
    text-align: center;
}

[data-editable] .text-left {
    text-align: left;
}

[data-editable] .text-right {
    text-align: right;
}
/*! ContentTools v1.5.4 by Anthony Blackshaw <ant@getme.co.uk> (https://github.com/anthonyjb) */

.ce--dragging,
.ce--resizing {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	user-select: none
}
.ce--dragging {
	cursor: move!important
}
.ce--resizing {
	cursor: nwse-resize!important
}
.ce-element--type-image,
.ce-element--type-video {
	background-repeat: no-repeat;
	position: relative;
	cursor: pointer;
	z-index: 1
}
.ce-element--type-image:after,
.ce-element--type-image:before,
.ce-element--type-video:after,
.ce-element--type-video:before {
	background: rgba(0, 0, 0, .5);
	border-radius: 2px;
	color: #fff;
	display: none;
	font-family: arial, sans-serif;
	font-size: 10px;
	line-height: 10px;
	padding: 4px 4px 3px;
	position: absolute
}
.ce-element--type-image:before,
.ce-element--type-video:before {
	content: attr(data-ce-size);
	right: 10px;
	top: 10px
}
.ce-element--type-image.ce-element--over:before,
.ce-element--type-image.ce-element--resizing:before,
.ce-element--type-video.ce-element--over:before,
.ce-element--type-video.ce-element--resizing:before {
	display: block
}
.ce-element--type-image {
	background-position: 0 0;
	background-size: cover
}
.ce-element--type-image:after {
	background: transparent;
	content: '';
	display: block;
	left: 0;
	position: relative;
	top: 0;
	height: 100%;
	width: 100%
}
.ce-element--type-video {
	background: #333 url(/static/media/video.afa41b74.svg) 50%/auto 48px no-repeat
}
.ce-element--type-video:after {
	bottom: 10px;
	content: attr(data-ce-title);
	display: block;
	left: 10px
}
.ce-element--empty:after {
	content: '...';
	display: inline-block;
	font-style: italic;
	opacity: .5
}
.ce-element--empty[data-ce-placeholder]:after {
	content: attr(data-ce-placeholder)
}
.ce-element--dragging {
	background-color: rgba(51, 51, 51, .1)!important;
	opacity: .5;
	z-index: -1
}
.ce-element--dragging.ce-element--type-image,
.ce-element--dragging.ce-element--type-video {
	background-color: #333!important;
	opacity: 1;
	outline-color: rgba(51, 51, 51, .1)!important
}
.ce-element--drop {
	position: relative!important
}
.ce-element--drop:before {
	background: #f39c12 url(/static/media/drop-vert-above.196754c8.svg) 50%/auto 32px repeat;
	bottom: 0;
	content: ''!important;
	left: 0;
	opacity: .8;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 9
}
.ce-element--drop-below:before {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}
.ce-element--drop-left:before {
	background-image: url(/static/media/drop-horz.d18cdced.svg);
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg)
}
.ce-element--drop-right:before {
	background-image: url(/static/media/drop-horz.d18cdced.svg);
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg)
}
.ce-element--drop.ce-element--type-table-row {
	background: #f39c12 url(/static/media/drop-vert-above.196754c8.svg) 50%/auto 32px repeat
}
.ce-element--drop.ce-element--type-table-row:before {
	display: none
}
.ce-element--drop.ce-element--type-table-row.ce-element--drop-below {
	background: #f39c12 url(/static/media/drop-vert-below.0816d061.svg) 50%/auto 32px repeat
}
.ce-element--focused,
.ce-element--over {
	background-color: rgba(243, 156, 18, .1);
	outline: none
}
.ce-element--focused.ce-element--type-image,
.ce-element--focused.ce-element--type-image-fixture,
.ce-element--focused.ce-element--type-video,
.ce-element--over.ce-element--type-image,
.ce-element--over.ce-element--type-image-fixture,
.ce-element--over.ce-element--type-video {
	background-color: #333;
	outline: 4px solid rgba(243, 156, 18, .35)
}
.ce-element--resize-top-left {
	cursor: nw-resize
}
.ce-element--resize-top-right {
	cursor: ne-resize
}
.ce-element--resize-bottom-right {
	cursor: se-resize
}
.ce-element--resize-bottom-left {
	cursor: sw-resize
}
.ce-drag-helper {
	background: #fff;
	border-radius: 2px;
	box-shadow: 0 3px 3px rgba(0, 0, 0, .25);
	color: #4e4e4e;
	font: arial, sans-serif;
	font-size: 12px;
	height: 120px;
	left: 0;
	line-height: 135%;
	margin: 5px 0 0 5px;
	overflow: hidden;
	padding: 15px;
	position: absolute;
	top: 0;
	width: 120px;
	word-wrap: break-word;
	z-index: 9
}
.ce-drag-helper:before {
	background: #2980b9;
	color: #fff;
	content: attr(data-ce-type);
	display: block;
	font-family: arial, sans-serif;
	font-size: 10px;
	line-height: 10px;
	padding: 4px 4px 3px;
	position: absolute;
	right: 0;
	top: 0
}
.ce-drag-helper--type-list-item-text:after,
.ce-drag-helper--type-list:after,
.ce-drag-helper--type-pre-text:after,
.ce-drag-helper--type-table-row:after,
.ce-drag-helper--type-table:after,
.ce-drag-helper--type-text:after {
	background-image: linear-gradient(hsla(0, 0%, 100%, 0), #fff 66%);
	bottom: 0;
	content: '';
	display: block;
	height: 40px;
	left: 0;
	position: absolute;
	width: 100%
}
.ce-drag-helper--type-image {
	background-repeat: no-repeat;
	background-size: cover
}
.ce-element--type-image,
.ce-element--type-video {
	display: block;
	margin-left: auto;
	margin-right: auto
}
.ce-element--type-image.align-left,
.ce-element--type-video.align-left {
	clear: none;
	float: left
}
.ce-element--type-image.align-right,
.ce-element--type-video.align-right {
	clear: none;
	float: right
}
.ce-measure {
	display: block!important
}
@font-face {
	font-family: icon;
	src: url(/static/media/icons.b8364c6a.woff);
	font-weight: 400;
	font-style: normal
}
.ct-widget,
.ct-widget * {
	box-sizing: border-box
}
.ct-widget * a,
.ct-widget * b,
.ct-widget * caption,
.ct-widget * div,
.ct-widget * form,
.ct-widget * i fieldset,
.ct-widget * iframe,
.ct-widget * label,
.ct-widget * legend,
.ct-widget * span,
.ct-widget * table,
.ct-widget * tbody,
.ct-widget * td,
.ct-widget * tfoot,
.ct-widget * th,
.ct-widget * thead,
.ct-widget * tr,
.ct-widget a,
.ct-widget b,
.ct-widget caption,
.ct-widget div,
.ct-widget form,
.ct-widget i fieldset,
.ct-widget iframe,
.ct-widget label,
.ct-widget legend,
.ct-widget span,
.ct-widget table,
.ct-widget tbody,
.ct-widget td,
.ct-widget tfoot,
.ct-widget th,
.ct-widget thead,
.ct-widget tr {
	border: 0;
	font-size: 100%;
	font: inherit;
	margin: 0;
	padding: 0;
	vertical-align: baseline
}
.ct-widget * ol,
.ct-widget * ul,
.ct-widget ol,
.ct-widget ul {
	list-style: none
}
.ct-widget * table,
.ct-widget table {
	border-collapse: collapse;
	border-spacing: 0
}
.ct-widget {
	opacity: 0;
	font-family: arial, sans-serif;
	font-size: 14px;
	line-height: 18px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	z-index: 9996
}
.ct-widget,
.ct-widget--active {
	transition-property: opacity;
	transition-duration: .25s;
	transition-timing-function: ease-in
}
.ct-widget--active {
	opacity: 1
}
.ct-widget .ct-attribute {
	border-bottom: 1px solid #eee;
	height: 48px;
	vertical-align: top
}
.ct-widget .ct-attribute:after {
	clear: both;
	content: "";
	display: table
}
.ct-widget .ct-attribute__name {
	background: #f6f6f6;
	border: none;
	color: #646464;
	float: left;
	height: 47px;
	outline: none;
	padding: 0 16px;
	font-family: arial, sans-serif;
	font-size: 14px;
	line-height: 48px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 25%
}
.ct-widget .ct-attribute__name--invalid {
	color: #e74c3c
}
.ct-widget .ct-attribute__value {
	-webkit-appearance: none;
	appearance: none;
	background: #fff;
	border: none;
	color: #646464;
	float: right;
	height: 47px;
	outline: none;
	padding: 0 16px;
	font-family: arial, sans-serif;
	font-size: 14px;
	line-height: 48px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 75%
}
.ct-widget .ct-crop-marks {
	height: 320px;
	left: 73px;
	position: absolute;
	top: 0;
	width: 427px
}
.ct-widget .ct-crop-marks__clipper {
	height: 100%;
	overflow: hidden;
	position: relative;
	width: 100%
}
.ct-widget .ct-crop-marks__ruler--top-left {
	position: absolute
}
.ct-widget .ct-crop-marks__ruler--top-left:after {
	border: 1px solid hsla(0, 0%, 100%, .5);
	border-bottom: none;
	border-right: none;
	box-shadow: -1px -1px 1px rgba(0, 0, 0, .25), inset 1px 1px 1px rgba(0, 0, 0, .25);
	content: '';
	height: 999px;
	left: 0;
	position: absolute;
	top: 0;
	width: 999px
}
.ct-widget .ct-crop-marks__ruler--bottom-right {
	position: absolute
}
.ct-widget .ct-crop-marks__ruler--bottom-right:after {
	border: 1px solid hsla(0, 0%, 100%, .5);
	border-top: none;
	border-left: none;
	bottom: 0;
	box-shadow: 1px 1px 1px rgba(0, 0, 0, .25), inset -1px -1px 1px rgba(0, 0, 0, .25);
	content: '';
	height: 999px;
	position: absolute;
	right: 0;
	width: 999px
}
.ct-widget .ct-crop-marks__handle {
	background: #2980b9;
	border: 1px solid #409ad5;
	border-radius: 7px;
	cursor: pointer;
	height: 15px;
	margin-left: -7px;
	margin-top: -7px;
	position: absolute;
	width: 15px
}
.ct-widget .ct-crop-marks__handle--bottom-right {
	margin-left: -8px;
	margin-top: -8px
}
.ct-widget .ct-crop-marks__handle:hover {
	background: #2e8ece
}
@-webkit-keyframes a {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
		-webkit-transform: transform
	}
	to {
		transform: translate(-50%, -50%) rotate(359deg);
		-webkit-transform: transform
	}
}
@keyframes a {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
		-webkit-transform: transform;
		transform: transform
	}
	to {
		transform: translate(-50%, -50%) rotate(359deg);
		-webkit-transform: transform;
		transform: transform
	}
}
/* METUN EDITOINTI */
.ct-widget.ct-dialog {
	background: #fff;
	box-shadow: 0 8px 8px rgba(0, 0, 0, .35);
	border-radius: 2px;
	width: auto;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	position: fixed;
	z-index: 10099
}
.ct-widget.ct-dialog--busy .ct-dialog__busy {
	display: block
}
.ct-widget.ct-dialog--busy .ct-dialog__body {
	/*opacity: .1
*/}
.ct-widget .ct-dialog__header {
	color: #a4a4a4;
	border-bottom: 1px solid #eee;
	height: 48px;
	padding: 0 16px;
	position: relative
}
.ct-widget .ct-dialog__caption {
	font-family: arial, sans-serif;
	font-size: 18px
}
.ct-widget .ct-dialog__caption,
.ct-widget .ct-dialog__close {
	line-height: 48px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}
.ct-widget .ct-dialog__close {
	border-left: 1px solid #eee;
	cursor: pointer;
	height: 48px;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	font-family: icon;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	speak: none;
	text-transform: none;
	width: 48px
}
.ct-widget .ct-dialog__close:before {
	content: '\EA0F'
}
.ct-widget .ct-dialog__close:hover:before {
	color: #646464
}
/* METUN EDITOINTI */
.ct-widget .ct-dialog__body {
	margin: auto;
	padding: 32px 64px;
	width: 700px
}
.ct-widget .ct-dialog__view {
	height: auto
}
.ct-widget .ct-dialog__controls {
	margin-top: 16px
}
.ct-widget .ct-dialog__controls:after {
	clear: both;
	content: "";
	display: table
}
.ct-widget .ct-dialog__busy {
	display: none;
	position: absolute
}
.ct-widget .ct-dialog__busy:before {
	-webkit-animation: a 5s linear;
	animation: a 5s linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	color: #a4a4a4;
	content: "\E994";
	left: 50%;
	position: fixed;
	top: 50%;
	font-family: icon;
	font-size: 80px;
	font-style: normal;
	font-weight: 400;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}
.ct-widget .ct-control-group {
	font-size: 0
}
.ct-widget .ct-control-group--center {
	text-align: center
}
.ct-widget .ct-control-group--left {
	float: left
}
.ct-widget .ct-control-group--right {
	float: right
}
.ct-widget .ct-control {
	margin-left: 16px;
	position: relative;
	/* METUN LISÄYS */
	-webkit-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.ct-widget .ct-control:first-child {
	margin-left: 0
}
.ct-widget .ct-control--icon {
	color: #a4a4a4;
	cursor: pointer;
	display: inline-block;
	height: 32px;
	line-height: 32px;
	text-align: center;
	font-family: icon;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	speak: none;
	text-transform: none;
	width: 32px
}
/*.ct-widget .ct-control--icon,
.ct-widget .ct-control--icon:after {
	border-radius: 2px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}
.ct-widget .ct-control--icon:after {
	background: #000;
	color: #fff;
	content: attr(data-ct-tooltip);
	display: block;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
	left: -35px;
	opacity: 0;
	padding: 0 8px;
	position: absolute;
	bottom: 37px;
	font-family: arial, sans-serif;
	font-size: 12px;
	line-height: 20px;
	visibility: hidden;
	width: 85px;
	word-break: break-word
}
.ct-widget .ct-control--icon:hover:after {
	opacity: .8;
	visibility: visible;
	-webkit-transition-property: opacity;
	transition-property: opacity;
	-webkit-transition-duration: 0s;
	transition-duration: 0s;
	-webkit-transition-timing-function: ease-in;
	transition-timing-function: ease-in;
	-webkit-transition-delay: 2s;
	transition-delay: 2s
}*/
.ct-widget .ct-control--icon:before {
	content: ''
}
.ct-widget .ct-control--icon:hover {
	background: #eee;
	color: #646464
}
.ct-widget .ct-control--active,
.ct-widget .ct-control--on {
	background: #a4a4a4;
	color: #fff
}
.ct-widget .ct-control--active:hover,
.ct-widget .ct-control--on:hover {
	background: #646464;
	color: #fff
}
.ct-widget .ct-control--rotate-ccw:before {
	content: '\E965'
}
.ct-widget .ct-control--rotate-cw:before {
	content: '\E966'
}
.ct-widget .ct-control--crop:before {
	content: '\EA57'
}
.ct-widget .ct-control--remove:before {
	content: '\E9AC'
}
.ct-widget .ct-control--styles:before {
	content: '\E90B'
}
.ct-widget .ct-control--attributes:before {
	content: '\E994'
}
.ct-widget .ct-control--code:before {
	content: '\EA80'
}
.ct-widget .ct-control--icon.ct-control--muted {
	cursor: default
}
.ct-widget .ct-control--icon.ct-control--muted:before {
	opacity: .5
}
.ct-widget .ct-control--icon.ct-control--muted:hover {
	color: #a4a4a4;
	background: transparent
}
.ct-widget .ct-control--text {
	background: #2980b9;
	border-radius: 2px;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	font-weight: 700;
	height: 32px;
	overflow: hidden;
	padding: 0 8px;
	text-align: center;
	text-overflow: ellipsis;
	font-family: arial, sans-serif;
	font-size: 14px;
	line-height: 32px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	vertical-align: top;
	width: 100px
}
.ct-widget .ct-control--text:hover {
	background: #2e8ece
}
.ct-widget .ct-control--apply,
.ct-widget .ct-control--insert,
.ct-widget .ct-control--ok {
	background: #27ae60
}
.ct-widget .ct-control--apply:hover,
.ct-widget .ct-control--insert:hover,
.ct-widget .ct-control--ok:hover {
	background: #2cc36b
}
.ct-widget .ct-control--warning {
	background: #F1AD55
}
.ct-widget .ct-control--warning:hover {
	background: #ED982F
}
.ct-widget .ct-control--cancel,
.ct-widget .ct-control--clear {
	background: #e74c3c
}
.ct-widget .ct-control--cancel:hover,
.ct-widget .ct-control--clear:hover {
	background: #ea6153
}
.ct-widget .ct-control--text.ct-control--muted {
	background: #ccc;
	cursor: default
}
.ct-widget .ct-control--text.ct-control--muted:hover {
	background: #ccc
}
.ct-widget .ct-control--upload {
	overflow: hidden
}
.ct-widget.ct-image-dialog--empty .ct-control--cancel,
.ct-widget.ct-image-dialog--empty .ct-control--clear,
.ct-widget.ct-image-dialog--empty .ct-control--crop,
.ct-widget.ct-image-dialog--empty .ct-control--insert,
.ct-widget.ct-image-dialog--empty .ct-control--rotate-ccw,
.ct-widget.ct-image-dialog--empty .ct-control--rotate-cw,
.ct-widget.ct-image-dialog--empty .ct-progress-bar,
.ct-widget.ct-image-dialog--populated .ct-control--cancel,
.ct-widget.ct-image-dialog--populated .ct-control--upload,
.ct-widget.ct-image-dialog--populated .ct-progress-bar,
.ct-widget.ct-image-dialog--uploading .ct-control--clear,
.ct-widget.ct-image-dialog--uploading .ct-control--crop,
.ct-widget.ct-image-dialog--uploading .ct-control--insert,
.ct-widget.ct-image-dialog--uploading .ct-control--rotate-ccw,
.ct-widget.ct-image-dialog--uploading .ct-control--rotate-cw,
.ct-widget.ct-image-dialog--uploading .ct-control--upload {
	display: none
}

/* METU LISÄYS */
.ct-widget.ct-dialog--busy .ct-control{
	background: #AAA;
	pointer-events: none
}

/* METU LISÄYS */
.ct-widget .ct-image-dialog__view {
	background: #eee;
	margin: auto;    
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	-webkit-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.ct-widget.ct-dialog--busy .ct-image-dialog__view img{
	visibility: hidden
}
.ct-widget .ct-image-dialog__view:empty {
	font-family: icon;
	font-size: 80px;
	font-style: normal;
	font-weight: 400;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 320px;
	text-align: center
}

/* METUN EDIT */
.ct-widget .ct-image-dialog__view:empty:before {
	content: '\E90D'    
}
.ct-widget:not(.ct-dialog--busy) .ct-image-dialog__view:empty:before {
	color: #fff;
}
.ct-widget.ct-dialog--busy .ct-image-dialog__view:empty:before {
	color: rgba(255, 255, 255, 0);
}
/* METUN POISTO .ct-widget .ct-image-dialog__image */

/* METUN LISÄYS */
img[rotation="90"] {
	transform: rotate(90deg);
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg)
}
img[rotation="180"] {
	transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg)
}
img[rotation="270"] {
	transform: rotate(270deg);
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg)
}
/* METU POISTI */

.ct-widget.ct-properties-dialog--attributes .ct-properties-dialog__attributes,
.ct-widget.ct-properties-dialog--styles .ct-properties-dialog__styles {
	display: block
}
.ct-widget.ct-properties-dialog--styles .ct-properties-dialog__styles:empty:before {
	color: #a4a4a4;
	content: attr(data-ct-empty);
	display: block;
	font-style: italic;
	margin-top: 20px;
	text-align: center
}
.ct-widget.ct-properties-dialog--code .ct-properties-dialog__code {
	display: block
}
.ct-widget .ct-properties-dialog__view {
	border: 1px solid #ddd;
	overflow: auto
}
.ct-widget .ct-properties-dialog__attributes,
.ct-widget .ct-properties-dialog__code,
.ct-widget .ct-properties-dialog__styles {
	display: none
}
.ct-widget .ct-properties-dialog__inner-html {
	border: none;
	display: block;
	font-family: courier, Bitstream Vera Sans Mono, Consolas, Courier, monospace;
	height: 318px;
	padding: 16px;
	outline: none;
	resize: none;
	width: 100%
}
.ct-widget .ct-properties-dialog__inner-html--invalid {
	color: #e74c3c
}
.ct-widget .ct-table-dialog__view {
	border: 1px solid #ddd;
	overflow: auto
}
.ct-widget .ct-video-dialog__preview:empty {
	background: #eee;
	font-family: icon;
	font-size: 80px;
	font-style: normal;
	font-weight: 400;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 320px;
	text-align: center
}
.ct-widget .ct-video-dialog__preview:empty:before {
	color: #fff;
	content: '\EA98'
}
.ct-widget .ct-video-dialog__input {
	border: none;
	border-bottom: 1px solid #eee;
	height: 32px;
	line-height: 32px;
	outline: none;
	padding: 0 4px;
	font-family: arial, sans-serif;
	font-size: 14px;
	line-height: 18px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	vertical-align: top;
	width: 456px
}
.ct-widget .ct-video-dialog__input:focus {
	border-bottom: 1px solid #e1e1e1
}
.ct-widget.ct-anchored-dialog {
	border-bottom: 2px solid #27ae60;
	box-shadow: 0 3px 3px rgba(0, 0, 0, .35);
	font-size: 0;
	height: 34px;
	left: 0;
	margin-left: -160px;
	margin-top: -48px;
	position: absolute;
	top: 0;
	width: 320px;
	z-index: 10099
}
.ct-widget.ct-anchored-dialog:after {
	border: 16px solid hsla(0, 0%, 100%, 0);
	border-top-color: #27ae60;
	content: '';
	left: 144px;
	position: absolute;
	top: 34px
}
.ct-widget .ct-anchored-dialog__input {
	border: none;
	color: #646464;
	outline: none;
	font-family: arial, sans-serif;
	font-size: 14px;
	padding: 0 8px 0 16px;
	vertical-align: top;
	width: 256px
}
.ct-widget .ct-anchored-dialog__button,
.ct-widget .ct-anchored-dialog__input {
	height: 32px;
	line-height: 32px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}
.ct-widget .ct-anchored-dialog__button {
	background: #27ae60;
	cursor: pointer;
	display: inline-block;
	text-align: center;
	font-family: icon;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	speak: none;
	text-transform: none;
	width: 32px
}
.ct-widget .ct-anchored-dialog__button:before {
	color: #fff;
	content: '\EA10'
}
.ct-widget .ct-anchored-dialog__button:hover {
	background: #2cc36b
}
.ct-widget .ct-anchored-dialog__target-button {
	background: #fff;
	cursor: pointer;
	display: inline-block;
	height: 32px;
	line-height: 32px;
	text-align: center;
	font-family: icon;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 32px
}
.ct-widget .ct-anchored-dialog__target-button:before {
	color: #a4a4a4;
	content: '\EA7D'
}
.ct-widget .ct-anchored-dialog__target-button:hover:before {
	color: #b1b1b1
}
.ct-widget .ct-anchored-dialog__target-button--active:before {
	color: #27ae60
}
.ct-widget .ct-anchored-dialog__target-button--active:hover:before {
	color: #2cc36b
}
@-webkit-keyframes b {
	0% {
		opacity: 0;
		font-size: 32px;
		-webkit-transform: font-size
	}
	25% {
		font-size: 320px;
		opacity: 1;
		-webkit-transform: all
	}
	50% {
		font-size: 320px;
		opacity: 1;
		-webkit-transform: all
	}
	75% {
		font-size: 320px;
		opacity: 1;
		-webkit-transform: all
	}
	to {
		opacity: 0;
		-webkit-transform: all
	}
}
@keyframes b {
	0% {
		opacity: 0;
		font-size: 32px;
		-webkit-transform: font-size;
		transform: font-size
	}
	25% {
		font-size: 320px;
		opacity: 1;
		-webkit-transform: all;
		transform: all
	}
	50% {
		font-size: 320px;
		opacity: 1;
		-webkit-transform: all;
		transform: all
	}
	75% {
		font-size: 320px;
		opacity: 1;
		-webkit-transform: all;
		transform: all
	}
	to {
		opacity: 0;
		-webkit-transform: all;
		transform: all
	}
}
@-webkit-keyframes c {
	0% {
		opacity: 1;
		-webkit-transform: opacity
	}
	99% {
		opacity: 1;
		-webkit-transform: opacity
	}
	to {
		opacity: 0;
		-webkit-transform: opacity
	}
}
@keyframes c {
	0% {
		opacity: 1;
		-webkit-transform: opacity;
		transform: opacity
	}
	99% {
		opacity: 1;
		-webkit-transform: opacity;
		transform: opacity
	}
	to {
		opacity: 0;
		-webkit-transform: opacity;
		transform: opacity
	}
}
.ct-widget.ct-flash {
	color: hsla(0, 0%, 100%, .9);
	height: 0;
	left: 0;
	position: fixed;
	font-family: icon;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	top: 0;
	width: 0;
	z-index: 9999
}
.ct-widget.ct-flash:before {
	left: 50%;
	opacity: 0;
	position: fixed;
	text-shadow: 0 0 20px rgba(0, 0, 0, .5);
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%)
}
.ct-widget.ct-flash--active {
	-webkit-animation: c 2s ease-in;
	animation: c 2s ease-in;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards
}
.ct-widget.ct-flash--active:before {
	-webkit-animation: b 2s ease-in;
	animation: b 2s ease-in;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	font-size: 320px;
	opacity: 1
}
.ct-widget.ct-flash--ok:before {
	content: '\EA10'
}
.ct-widget.ct-flash--no:before {
	content: '\EA0F'
}
.ct-widget .ct-grip {
	cursor: move;
	font-size: 0;
	text-align: center;
	-webkit-user-select: none;
	user-select: none
}
.ct-widget .ct-grip__bump {
	background: rgba(70, 70, 70, .15);
	border-radius: 12px;
	display: inline-block;
	height: 12px;
	margin-left: 12px;
	width: 12px
}
.ct-widget .ct-grip__bump:first-child {
	margin-left: 0
}
@-webkit-keyframes d {
	0% {
		transform: rotate(0deg);
		-webkit-transform: transform
	}
	to {
		transform: rotate(359deg);
		-webkit-transform: transform
	}
}
@keyframes d {
	0% {
		transform: rotate(0deg);
		-webkit-transform: transform;
		transform: transform
	}
	to {
		transform: rotate(359deg);
		-webkit-transform: transform;
		transform: transform
	}
}
.ct-widget.ct-ignition {
	left: 16px;
	display: flex;
	position: fixed;
	top: 16px
}
.ct-widget.ct-ignition .ct-ignition__button {
	display: none
}
.ct-widget.ct-ignition--editing .ct-ignition__button--cancel,
.ct-widget.ct-ignition--editing .ct-ignition__button--confirm,
.ct-widget.ct-ignition--ready .ct-ignition__button--edit,
.ct-widget.ct-ignition--ready .ct-ignition__button--language,
.ct-widget.ct-ignition--ready .ct-ignition__button--publish,
.ct-widget.ct-ignition--ready .ct-ignition__button--settings,
.ct-widget.ct-ignition--ready .ct-ignition__button--sign-out {
	display: block
}
.ct-widget.ct-ignition--busy .ct-ignition__button {
	display: none
}
.ct-widget.ct-ignition--busy .ct-ignition__button--busy {
	display: block
}
.ct-widget .ct-ignition__button,
.ct-widget .ct-ignition__sub_button {
	content: '';
	cursor: pointer;
	display: block;
	opacity: .9;
	margin: 6px;
	text-align: center;
	font-family: icon;
	font-style: normal;
	font-weight: 400;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.ct-widget .ct-ignition__button {
	border-radius: 24px;
	height: 48px;
	line-height: 48px;
	font-size: 24px;
	width: 48px
}
.ct-widget .ct-ignition__sub_button {
    border-radius: 18px;
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    width: 34px;
    margin-left: 8px;
    font-weight: bold;
}
.ct-widget .ct-ignition__button:not([data-ct-opened]) .ct-ignition__sub_button { display: none; }
.ct-widget .ct-ignition__sub_button { pointer-events: all; color: black; }
/* .ct-widget .ct-ignition__sub_button:hover { margin-left: 10px; } */

.ct-widget .ct-ignition__button:before { color: #fff }
.ct-widget .ct-ignition__button--busy {
	-webkit-animation: d 5s linear;
	animation: d 5s linear;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	background: #646464;
	cursor: default
}
.ct-widget .ct-ignition__button--busy:before { content: '\E994' }
.ct-widget .ct-ignition__button--busy:hover { background: #646464 }

.ct-widget .ct-ignition__button--confirm { background: #27ae60 }
.ct-widget .ct-ignition__button--confirm:before { content: '\EA10' }
.ct-widget .ct-ignition__button--confirm:hover { background: #2cc36b }

.ct-widget .ct-ignition__button--cancel { background: #e74c3c; }
.ct-widget .ct-ignition__button--cancel:before { content: '\EA0F' }
.ct-widget .ct-ignition__button--cancel:hover {	background: #ea6153 }

.ct-widget .ct-ignition__button--edit {	background: #2980b9 }
.ct-widget .ct-ignition__button--edit:before {
	content: '\E905';
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	transition-duration: .1s;
	transition-timing-function: ease-in
}
.ct-widget .ct-ignition__button--edit:not([disabled]):hover { background: #2e8ece }
.ct-widget .ct-ignition__button--edit:not([disabled]):hover:before {
	display: inline-block;
	-webkit-transform: rotate(-15deg);
	transform: rotate(-15deg)
}
/* .ct-widget .ct-ignition__button--language[data-ct-language=fi] { background: url(images/flags/fi.svg); }
.ct-widget .ct-ignition__button--language[data-ct-language=gb] { background: url(images/flags/gb.svg); }
.ct-widget .ct-ignition__button--language[data-ct-language=se] { background: url(images/flags/se.svg); }
.ct-widget .ct-ignition__button--language:not([disabled]):before { content: '\n'; }
.ct-widget .ct-ignition__button--language:not([disabled]):hover,
.ct-widget .ct-ignition__button--language[data-ct-opened] { opacity: 1; }
.ct-widget .ct-ignition__button--language .ct-ignition__sub_button:hover { transform: scale(1.1,1.1); }
.ct-widget .ct-ignition__button--language,
.ct-widget .ct-ignition__button--language .ct-ignition__sub_button {
	background-size:     cover !important;
    background-repeat:   no-repeat !important;
	background-position: center center !important;
}

.ct-widget .ct-ignition__sub_button--lang[value=fi] { background: url(images/flags/fi.svg); }
.ct-widget .ct-ignition__sub_button--lang[value=gb] { background: url(images/flags/gb.svg); }
.ct-widget .ct-ignition__sub_button--lang[value=se] { background: url(images/flags/se.svg); } */

.ct-widget .ct-ignition__button--publish { background: #17A33A; }
.ct-widget .ct-ignition__button--publish:not([disabled]):before { content: '\E906'; }
.ct-widget .ct-ignition__button--publish:not([disabled]):hover { background: #19C243 }

.ct-widget .ct-ignition__button--settings { background: #e6a236; }
.ct-widget .ct-ignition__button--settings:not([disabled]):before { content: '\E994'; }
.ct-widget .ct-ignition__button--settings:not([disabled]):hover { background: #fbb94f }

.ct-widget .ct-ignition__button--sign-out { background: rgb(163, 23, 23); }
.ct-widget .ct-ignition__button--sign-out:not([disabled]):before { content: '\E907'; padding-left: 4px; top: 1px; }
.ct-widget .ct-ignition__button--sign-out:not([disabled]):hover { background: rgb(194, 25, 25); }

.ct-ignition__button[disabled] {
	opacity: 0.6;
	background: #818181;
	cursor: default;
}
.ct-widget.ct-inspector {
	background: hsla(0, 0%, 91%, .5);
	border-top: 1px solid hsla(0, 0%, 100%, .2);
	bottom: 0;
	height: 32px;
	left: 0;
	overflow: hidden;
	padding: 3px 16px 0;
	position: fixed;
	width: 100%
}
.ct-widget .ct-inspector__tags {
	width: calc(100% - 128px)
}
.ct-widget .ct-inspector__tags:after {
	clear: both;
	content: "";
	display: table
}
.ct-widget .ct-inspector__tags:before {
	color: #464646;
	content: '\EA80';
	display: block;
	float: left;
	height: 24px;
	line-height: 24px;
	margin-right: 16px;
	text-align: center;
	font-family: icon;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	speak: none;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 24px
}
.ct-widget .ct-inspector__counter {
	border-left: 1px solid rgba(0, 0, 0, .1);
	height: 24px;
	line-height: 24px;
	margin-right: 16px;
	position: absolute;
	right: 0;
	text-align: right;
	top: 3px;
	width: 128px
}
.ct-widget .ct-tag {
	background-color: #2980b9;
	border-radius: 2px 0 0 2px;
	color: #fff;
	cursor: pointer;
	float: left;
	font-weight: 700;
	height: 24px;
	line-height: 24px;
	margin-left: 24px;
	padding: 0 8px;
	position: relative;
	text-shadow: 0 1px 0 rgba(0, 0, 0, .35)
}
/* METU EDIT */
.ct-widget .ct-tag:after {
	border-style: solid;
	border-bottom: 12px solid rgba(255, 0, 0, 0);
	border-left: 12px solid #2980b9;
	border-right: none;
	border-top: 12px solid rgba(255, 0, 0, 0);
	content: '';
	display: block;
	height: 0px;
	bottom: 0;
	right: -36px;
	position: absolute;
	width: 24px;
	box-sizing: content-box;
}
.ct-widget .ct-tag:first-child {
	margin-left: 0
}
.ct-widget .ct-tag:hover {
	background-color: #4aa3df
}
.ct-widget .ct-tag:hover:after {
	border-left-color: #4aa3df
}
.ct-widget .ct-tag:nth-child(1) {
	background-color: #8e44ad
}
.ct-widget .ct-tag:nth-child(1):after {
	border-left-color: #8e44ad
}
.ct-widget .ct-tag:nth-child(1):hover {
	background-color: #9b50ba
}
.ct-widget .ct-tag:nth-child(1):hover:after {
	border-left-color: #9b50ba
}
.ct-widget .ct-tag:nth-child(2) {
	background-color: #2980b9
}
.ct-widget .ct-tag:nth-child(2):after {
	border-left-color: #2980b9
}
.ct-widget .ct-tag:nth-child(2):hover {
	background-color: #2e8ece
}
.ct-widget .ct-tag:nth-child(2):hover:after {
	border-left-color: #2e8ece
}
.ct-widget .ct-tag:nth-child(3) {
	background-color: #27ae60
}
.ct-widget .ct-tag:nth-child(3):after {
	border-left-color: #27ae60
}
.ct-widget .ct-tag:nth-child(3):hover {
	background-color: #2cc36b
}
.ct-widget .ct-tag:nth-child(3):hover:after {
	border-left-color: #2cc36b
}
.ct-widget .ct-tag:nth-child(4) {
	background-color: #d35400
}
.ct-widget .ct-tag:nth-child(4):after {
	border-left-color: #d35400
}
.ct-widget .ct-tag:nth-child(4):hover {
	background-color: #ed5e00
}
.ct-widget .ct-tag:nth-child(4):hover:after {
	border-left-color: #ed5e00
}
.ct-widget .ct-tag:nth-child(5) {
	background-color: #f39c12
}
.ct-widget .ct-tag:nth-child(5):after {
	border-left-color: #f39c12
}
.ct-widget .ct-tag:nth-child(5):hover {
	background-color: #f4a62a
}
.ct-widget .ct-tag:nth-child(5):hover:after {
	border-left-color: #f4a62a
}
.ct-widget .ct-tag:nth-child(6) {
	background-color: #16a085
}
.ct-widget .ct-tag:nth-child(6):after {
	border-left-color: #16a085
}
.ct-widget .ct-tag:nth-child(6):hover {
	background-color: #19b698
}
.ct-widget .ct-tag:nth-child(6):hover:after {
	border-left-color: #19b698
}
.ct-widget.ct-modal {
	background: rgba(0, 0, 0, .7);
	height: 0;
	left: 0;
	position: fixed;
	top: 0;
	width: 0;
	z-index: 10009
}
.ct-widget.ct-modal--transparent {
	background: transparent
}
.ct-widget--active.ct-modal {
	height: 100%;
	width: 100%
}
.ct-widget .ct-progress-bar {
	border: 1px solid #eee;
	height: 32px;
	line-height: 32px;
	padding: 1px;
	width: 456px
}
.ct-widget .ct-progress-bar__progress {
	background: #2980b9;
	height: 28px
}
.ct-widget .ct-section {
	border-bottom: 1px solid #eee;
	color: #bdbdbd;
	cursor: pointer;
	font-style: italic;
	height: 48px;
	padding: 0 16px;
	font-family: arial, sans-serif;
	font-size: 16px;
	line-height: 48px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}
.ct-widget .ct-section:after {
	clear: both;
	content: "";
	display: table
}
.ct-widget .ct-section:hover {
	background: #f6f6f6
}
.ct-widget .ct-section--applied {
	color: #646464;
	font-style: normal
}
.ct-widget .ct-section--applied .ct-section__switch {
	background-color: #27ae60;
	border: 1px solid #1e8449
}
.ct-widget .ct-section--applied .ct-section__switch:before {
	left: 25px;
	transition-property: left;
	transition-duration: .1s;
	transition-timing-function: ease-in
}
.ct-widget .ct-section--contains-input .ct-section__label {
	width: 75%
}
.ct-widget .ct-section__label {
	float: left;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 472px;
	white-space: nowrap
}
.ct-widget .ct-section__switch {
	background-color: #ccc;
	border: 1px solid #b3b3b3;
	border-radius: 12px;
	box-shadow: inset 0 0 2px rgba(0, 0, 0, .1);
	float: right;
	height: 24px;
	margin-top: 12px;
	position: relative;
	width: 48px
}
.ct-widget .ct-section__switch:before {
	background: #fff;
	border-radius: 10px;
	content: '';
	height: 20px;
	left: 1px;
	position: absolute;
	top: 1px;
	transition-property: left;
	transition-duration: .1s;
	transition-timing-function: ease-in;
	width: 20px
}
.ct-widget .ct-section__input {
	background: #fff;
	border: none;
	color: #646464;
	float: right;
	height: 47px;
	outline: none;
	padding: 0 16px;
	text-align: right;
	font-family: arial, sans-serif;
	font-size: 14px;
	line-height: 48px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 25%
}
.ct-widget .ct-section__input--invalid {
	color: #e74c3c
}
.ct-widget.ct-toolbox {
	background: hsla(0, 0%, 91%, .9);
	border: 1px solid hsla(0, 0%, 100%, .5);
	box-shadow: 0 3px 3px rgba(0, 0, 0, .35);
	left: 128px;
	padding: 8px;
	position: fixed;
	top: 128px;
	width: 138px
}
.ct-widget.ct-toolbox--dragging {
	opacity: .5
}
.ct-widget .ct-toolbox__grip {
	padding: 8px 0
}
.ct-widget .ct-tool-group {
	padding: 4px 0
}
.ct-widget .ct-tool-group:after {
	clear: both;
	content: "";
	display: table
}
.ct-widget .ct-tool-group:first-child {
	padding-top: 0
}
.ct-widget .ct-tool {
	color: #464646;
	float: left;
	height: 32px;
	margin: 4px;
	margin-right: 4px;
	position: relative;
	text-align: center;
	font-family: icon;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	speak: none;
	text-transform: none;
	width: 32px
}
.ct-widget .ct-tool:not(.ct-tool--disabled) {
	cursor: pointer;
}

*[data-ct-tooltip]:not([data-ct-opened]):after {
	z-index: 9998;
	background: rgb(15, 15, 15);
	color: #fff;
	pointer-events:none;
	word-break: break-word;
	border-radius: 4px;
	content: attr(data-ct-tooltip);
	display: block;
	position: relative;
	-webkit-hyphens: auto;
	hyphens: auto;
	opacity: 0;
	font-family: arial, sans-serif;
	font-size: 12px;
	text-align: center;
	line-height: 16px;
	margin-top: 2px;
	padding: 2px 8px;
	box-sizing: content-box;
}

.ct-control[data-ct-tooltip]:after,
.ct-tool[data-ct-tooltip]:after {
	position: absolute;
	left: -28px;
	width: 70px;
}
.ct-control[data-ct-tooltip]:hover:after,
.ct-tool[data-ct-tooltip]:not(.ct-tool--disabled):hover:after {
	-webkit-animation: animationToVisible 0.8s;
	-webkit-animation-fill-mode: forwards;
	animation: animationToVisible 0.8s;
	animation-fill-mode: forwards;
}
@-webkit-keyframes animationToVisible {
	0% { visibility: hidden; }
	80% { visibility: visible; opacity: 0 }
	100% { opacity: 1 }
}
@keyframes animationToVisible {
	0% { visibility: hidden; }
	80% { visibility: visible; opacity: 0 }
	100% { opacity: 1 }
}
.ct-background-image-edit__button[data-ct-tooltip]:after {
	left: -13px;
	font-size: 12px;
	line-height: 20px;
	width: 68px;
}
.ct-ignition__button[data-ct-tooltip]:after {
	left: -12px;
	font-size: 12px;
	line-height: 20px;
	width: 56px;
}
.ct-background-image-edit__button[data-ct-tooltip]:after,
.ct-ignition__button[data-ct-tooltip]:after {
	transition: opacity 0.1s ease-in-out;
}
.ct-background-image-edit__button[data-ct-tooltip]:hover:after,
.ct-ignition__button:not([disabled])[data-ct-tooltip]:hover:after {
	opacity: 1;	
}
.ct-widget .ct-tool:before {
	line-height: 32px
}
.ct-widget .ct-tool:nth-child(3n) {
	margin-right: 0
}
.ct-widget .ct-tool:hover {
	background: hsla(0, 0%, 100%, .5)
}
.ct-widget .ct-tool--disabled {
	color: rgba(70, 70, 70, .33)
}
.ct-widget .ct-tool--disabled:hover {
	background: transparent
}
.ct-widget .ct-tool--down {
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, .25);
	line-height: 34px
}
.ct-widget .ct-tool--down,
.ct-widget .ct-tool--down:hover {
	background: rgba(0, 0, 0, .025)
}
.ct-widget .ct-tool--applied {
	background: rgba(0, 0, 0, .1);
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, .25)
}
.ct-widget .ct-tool--applied:hover {
	background: rgba(0, 0, 0, .15)
}
.ct-widget .ct-tool--bold:before {
	content: "\EA62"
}
.ct-widget .ct-tool--heading:before {
	content: "H";
	font-weight: 700
}
.ct-widget .ct-tool--subheading:before {
	content: "H"
}
.ct-widget .ct-tool--paragraph:before {
	content: "P"
}
.ct-widget .ct-tool--preformatted:before {
	content: "\EA80"
}
.ct-widget .ct-tool--italic:before {
	content: "\EA64"
}
.ct-widget .ct-tool--link:before {
	content: "\E9CB"
}
.ct-widget .ct-tool--align-left:before {
	content: "\EA77"
}
.ct-widget .ct-tool--align-center:before {
	content: "\EA78"
}
.ct-widget .ct-tool--align-right:before {
	content: "\EA79"
}
.ct-widget .ct-tool--unordered-list:before {
	content: "\E9BA"
}
.ct-widget .ct-tool--ordered-list:before {
	content: "\E9B9"
}
.ct-widget .ct-tool--table:before {
	content: "\EA71"
}
.ct-widget .ct-tool--indent:before {
	content: "\EA7B"
}
.ct-widget .ct-tool--unindent:before {
	content: "\EA7C"
}
.ct-widget .ct-tool--line-break:before {
	content: "\EA6E"
}
.ct-widget .ct-tool--image:before {
	content: "\E90D"
}
.ct-widget .ct-tool--video:before {
	content: "\EA98"
}
.ct-widget .ct-tool--undo:before {
	content: "\E965"
}
.ct-widget .ct-tool--redo:before {
	content: "\E966"
}
.ct-widget .ct-tool--remove:before {
	content: "\E9AC"
}
@-webkit-keyframes e {
	0% {
		outline-color: hsla(0, 0%, 100%, 0);
		-webkit-transform: background-color
	}
	25% {
		outline-color: #f39c12;
		-webkit-transform: background-color
	}
	50% {
		outline-color: #f39c12;
		-webkit-transform: background-color
	}
	to {
		outline-color: hsla(0, 0%, 100%, 0);
		-webkit-transform: background-color
	}
}
@keyframes e {
	0% {
		outline-color: hsla(0, 0%, 100%, 0);
		-webkit-transform: background-color;
		transform: background-color
	}
	25% {
		outline-color: #f39c12;
		-webkit-transform: background-color;
		transform: background-color
	}
	50% {
		outline-color: #f39c12;
		-webkit-transform: background-color;
		transform: background-color
	}
	to {
		outline-color: hsla(0, 0%, 100%, 0);
		-webkit-transform: background-color;
		transform: background-color
	}
}
.ct-app,
.ct-app *,
.ct-app:after,
.ct-app:before {
	box-sizing: border-box
}
.ct--highlight {
	outline: 4px solid #f39c12;
	-webkit-animation: e 1s ease-in;
	animation: e 1s ease-in;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards
}
.ct--no-scroll {
	overflow: hidden
}
.ct--puesdo-select {
	background: rgba(0, 0, 0, .1)
}
*[data-ct-editable-content] .ce-element {
	pointer-events: all;
	cursor: text;
}
*[data-ct-editable-background],
*[data-ct-editable-content] .ce-element {
	transition-property: none !important;
	-webkit-transform: none !important;
	transform: none !important;
	-webkit-animation: none !important;
	animation: none !important;
}
.ct-content-highlighted {
	position: relative;
}
.ct-content-highlighted:after {
	content: '';
	position: absolute;
	pointer-events: none;
	top: -6px;
	right: -6px;
	bottom: -6px;
	left: -6px;
	border: 2px solid #dba718;
	box-shadow: 0px 0px 4px#dba718;
	border-radius: 2px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
[data-ct-editable-background] {
	position: relative;
}
.ct-background-image-edit__button {
	z-index: 9996;
	position: absolute;
	top: 20px;
	right: 24px;
	width: 56px;
	height: 56px;
	background: rgb(58, 58, 58);
	opacity: 0.4;
	box-shadow: 0 0 10px rgba(0,0,0,.2);
	transition: 0.3s;
	border-radius: 4px;
	text-align: center;
}
.ct-background-image-edit__button:hover {
	opacity: 1;
	box-shadow: 0 0 10px rgba(0,0,0,.6);
	transition: 0.3s;
	cursor: pointer;
}
.ct-background-image-edit__button:before {
	line-height: 56px;
	font-size: 26px;
	color: rgb(255, 255, 255);
	font-family: icon;
	content: '\E90D';
}
.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.ct-progress-bar {
	height: 20px;
	margin-bottom: 20px;
	background-color: #f5f5f5;
	border-radius: 4px;
	box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}
.ct-progress-striped {
 background-image: linear-gradient(45deg,rgba(255,255,255,.1) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.1) 50%,rgba(255,255,255,.1) 75%,transparent 75%,transparent);
 background-size: 80px 80px;
 -webkit-animation: ct-progress-stripes 4s linear infinite;
 animation: ct-progress-stripes 4s linear infinite;
}
.ct-progress {
	width: 0;
	height: 100%;
	overflow: hidden;
	line-height: 30px !important;
	font-size: 15px !important;
	font-weight: bold !important;
	color: #fff;
	text-align: center;
	background-color: #4CAF50;
	border-radius: 2px;
	pointer-events: none;
	box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
}
.ct-progressing {
	transition: width .6s ease;
}
@-webkit-keyframes ct-progress-stripes {
 0% { background-position: 80px 0 }
 100% { background-position: 0 0 }
}
@keyframes ct-progress-stripes {
 0% { background-position: 80px 0 }
 100% { background-position: 0 0 }
}
h2.section-title {
    text-transform: uppercase;
    padding: 20px 5px;;
}
.staff-edit-controls {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 99; }
  .staff-edit-controls button {
    border-radius: 40px;
    padding: 10px;
    border: none;
    outline: none;
    color: white;
    background-color: #17A33A;
    transition: 0.2s; }
    .staff-edit-controls button:disabled {
      background-color: #6b6b6b; }
    .staff-edit-controls button:hover {
      cursor: pointer;
      background-color: #4da84d; }
  .staff-edit-controls .button-red {
    color: white;
    background-color: #a31717; }
    .staff-edit-controls .button-red:hover {
      cursor: pointer;
      background-color: #964141; }
    .staff-edit-controls .button-red:disabled {
      background-color: #6b6b6b; }

.staff-item-controls button {
  outline: none;
  border: none;
  width: 32px;
  height: 32px;
  color: white;
  border-radius: 50%;
  margin: 0 4px; }
  .staff-item-controls button:hover {
    cursor: pointer; }

.staff-item-controls .arrow-button {
  background-color: #17A33A; }
  .staff-item-controls .arrow-button:hover {
    background-color: #4da84d; }
  .staff-item-controls .arrow-button:disabled {
    background-color: #6b6b6b; }

.staff-item-controls .remove-button {
  background-color: #a31717; }
  .staff-item-controls .remove-button:hover {
    background-color: #964141; }
  .staff-item-controls .remove-button:disabled {
    background-color: #6b6b6b; }

.avatar-div {
  background-position: 50%;
  background-size: cover;
  width: 160px;
  height: 180px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px; }

.add-new-container {
  height: 394px;
  align-self: center;
  width: 33.33333%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .add-new-container:hover {
    cursor: pointer; }
  .add-new-container .add-new-circle {
    border-radius: 50%;
    background-color: #17A33A;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    color: white;
    transition: 0.2s;
    margin-bottom: 16px; }
    .add-new-container .add-new-circle:hover {
      background-color: #4da84d; }

.google-map {
    height: 100%;
/*    min-height: 500px;*/
}
.contact-header {
    background-position: center;
    background-size: cover;
    height: 75vh;
    width: auto;
    position: relative;
}

.header-contact-info-box {
    padding: 18px;
    padding-left: 40px;
    padding-right: 40px;
    background: #fff;
    max-width: 50%;
    position: absolute;
    bottom: 15vh;
    left: 0;
}

@media (min-width: 993px) {
    
    .header-contact-info-box {
        display: none;
    }
    
    .slide-from-left {
        -webkit-animation-name: example;
        -webkit-animation-duration: 1s;
        animation-name: example;
        animation-duration: 1s;
        display: block;
    }
}


.header-contact-info-box p {
    line-height: 22px;
    font-size: 20px;
    font-weight: 500;
}




/* Safari 4.0 - 8.0 */
@-webkit-keyframes example {
    0%   {left: -50%}
    100% {left:0px}
}

/* Standard syntax */
@keyframes example {
    0%   {left: -50%}
    100% {left:0px}
}





@media (max-width: 992px) {
    
    .header-contact-info-box {
        width: 100%;
        max-width: 100%;
        bottom: 0;
        left: 0;
        padding: 20px;
        text-align: center;
        padding-top: 4rem;
        padding-bottom: 0;
    }

    .contact-header {
        height: calc(50vh + 250px);
    }
    
    .header-contact-info-box h2 {
        padding-bottom: 1rem;
    }
    
}

@media (max-width: 576px) {
    
    .header-contact-info-box h2 {
        font-size: 2rem;
    }
    
    .header-contact-info-box p {
        font-size: 1rem;
    }
    
}

@media (max-width: 350px) {
    
    .header-contact-info-box h2 {
        font-size: 1.8rem;
    }
    
    
}
.navbar {
    transition: all 0.4s ease-out;
    background: #fff;
    min-height: 52px;
}

.navbar.transparent {
    background: none;
}

.nav-item {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;
    display: block;
}


.nav-link {
    font-size: 1.2rem !important;
    color: #000;
    text-transform: uppercase;
}

.nav-link:visited {
    font-size: 1.3rem !important;
    color: #000;
}
.nav-link:hover {
    opacity: 0.6;
    font-size: 1.2rem !important;
    color: #000;
    cursor: pointer;
}

.nav-link:active {
    color: #000;
}


.navbar.transparent .nav-link {
    color: #fff;
}

.navbar.transparent .nav-link:visited {
    color: #fff;
}

.navbar.transparent .nav-link:hover {
    opacity: 0.6 !important;
    color: #fff;
}

.navbar.transparent .nav-link:active {
    color: #000;
}

ink:focus {
    outline: 0;
}


.navbar.center .navbar-inner {
    text-align: center;
}

.navbar.center .navbar-inner .nav {
    display:inline-block;
    float: none;
}

#navbarNavAltMarkup {
    float: right;
    height: 60px;
}


.navbar-logo {
    width: 200px;
    padding: 8px 0;
    height: 51px;
}

.navbar-toggler {
    padding-top: 10px;
}

.navbar-toggler:focus {
   outline: none;
}

@media (max-width: 1200px) {
    
    .nav-item {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }
}

@media (max-width: 992px) {
    
    .navbar-logo {
        width: 150px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    
    .navbar {
        padding-right:0;
    }
    
    .navbar-nav {
        padding-top: 34px;
    }

}

@media (max-width: 768px) {

    .navbar-toggler {
        margin-right: -10px;
    }
}


.main-header {
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: auto;
    position: relative;
    overflow: hidden;
}

span.nav-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
}


.header-dark-layer {
    height: 100%;
    background-color: black;
    opacity: 0.35;
}



.header-title-container {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    color: #fff;
    pointer-events: none;
}

.center-header-title-container {
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.center-header-title-container h2 {
    font-size: 6rem;
    padding: 0 40px;
    text-transform: uppercase;
    line-height: 7rem;
    max-width: 1800px;
}

@media (max-width: 1800px) {
    .center-header-title-container h2 {
        font-size: 5rem;
        line-height: 6rem;
    }
}


@media (max-width: 992px) {
    .center-header-title-container h2 {
        font-size: 4rem;
        line-height: 4.5rem;
    }
    .main-header {
        height: calc(100vh - 59px);
    }
    
    .center-header-title-container h2 {
        padding-bottom: 59px;
    }
    
}

@media (max-width: 768px) {
    .center-header-title-container h2 {
        font-size: 3.2rem;
        line-height: 3.5rem;
    }
}


@media (max-width: 576px) {
    .center-header-title-container h2 {
        font-size: 2.15rem;
        line-height: 2.5rem;
    }
}
.arrow-row {
    position: absolute;
    bottom: 12px;
    width: 100%;
}

.arrow-container {
    background-color: #fff;
    height: 58px;
    width: 58px;
    border-radius: 29px;
    padding-top: 16px;
    transition: all 0.4s;
    border-bottom-color: black;
}

.down-arrow {
    
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-style: solid;
    border-color: inherit;
    border-width: 0px 2px 2px 0px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    transition: border-width 450ms ease-in-out;
}


.arrow-container:hover, .arrow-container:focus {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
    border-color: #930000;
    -webkit-transform: scale3d(1.05, 1.05, 1);
            transform: scale3d(1.05, 1.05, 1);
    cursor: pointer;
}




@media (max-width: 576px) {
    .arrow-container {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        padding-top: 11px;
    }
    
    .down-arrow {
        height: 14px;
        width: 14px;
    }

}



.intro-jumbotron {
    padding-top: 0;
}

.intro-text-box {
    max-width: 1200px;
    padding: 20px 40px;
    text-align: center;
}

.intro-text-box p {
    font-size: 1.2rem;
}

.intro-text-box a {
    color: #930000;
}

@media (max-width: 768px) {
    .intro-text-box p {
        font-size: 1.1rem;
    }

    .intro-text-box {
        padding: 20px;
    }
}

.button-more {
    display: block;
    margin: auto;
    border: solid 2px #930000;
    color: #930000;
    text-transform: uppercase;
    background: none;
    font-size: 1rem;
    width: 150px;
    height: 40px;
    padding-top: 6px;
    border-radius: 1px;
    text-align: center;
    letter-spacing: 0.06rem;
    text-decoration: none;
}

.button-more-bigger {
    padding-top: 10px;
    width: 200px;
    height: 46px;
}

.button-more:focus {
    text-decoration: none;
    color: #930000;
}

.button-more:hover {
    cursor: pointer;
    background-color: #930000;
    color: #fff;
    text-decoration: none;
}



div.moreButtonBox {
    padding-top: 10px;
    width: 100%;
}

.projectContainer {
    background-color: white;
    padding: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    text-align: center;
    transition: all 0.7s;
    height: 50vh;

    background-position: center;
    background-size: cover;
}

.projectContainer h4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0;
    background-color: rgba(255,255,255,0.7);
    width: 100%;
}


.projectContainer:hover, .projectContainer:focus {

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    -webkit-transform: scale3d(1.02, 1.02, 1);
            transform: scale3d(1.02, 1.02, 1);
    cursor: pointer;
    
}


.bottom-row {
    height: 100%;
    display: flex;
}


.defaultTextView {
    margin-top: auto;
    
}




.col-project-container {
    padding: 0;
    overflow: hidden;
    position: relative;
}



.project-dark-layer {
    height: 100%;
    background-color: black;
    opacity: 0.3;
    transition: all 0.7s;
}

.project-dark-layer:hover {
    height: 100%;
    background-color: black;
    opacity: 0.65
}





.project-title-container {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    color: #fff;
    pointer-events: none;
}

.center-title-container {
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.center-title-container h3 {
    font-size: 1.7rem;
    margin: 1rem;
}



@media (max-width: 1500px) {
    .center-title-container h3 {
        font-size: 1.5rem;
    }
}
section.about-us {
    padding-top: 0;
    padding-bottom: 0;
    box-sizing: border-box;
}

.container-about-us {
    min-height: 100vh;
    padding: 50px;
    max-width: 1800px;
    padding-top: 0;
    padding-bottom: 0;
}

.about-row {
    min-height: 100vh;
}

.aboutImageContainer {
    padding: 5rem;
    padding-left: 3.5rem;
    padding-right: 0;
}

.aboutUsImageBox {
    background-position: center;
    background-size: cover;
    height: 800px;
    max-height: 70vh;
    
}

.about-text-container {
    
    max-width: 650px;
    padding: 1rem;
    padding-top: 200px;
    padding-bottom: 3rem;
}



.center-this-col-lg {
    display: block;
    margin: auto;
}


.more-button-container {
    width: 150px;
}




@media (max-width: 1800px) {
    
    .aboutImageContainer {
        padding-left: 2rem;
    }

}


@media (max-width: 1200px) {
    
    .aboutUsImageBox {
        height: 50vh;
    }
}


@media (max-width: 992px) {
    
    .container-about-us {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    
    .about-text-container {
        padding: 2rem;
        max-width: none;
    }
    
    .aboutImageContainer {
        padding: 0;
    }
    
    .center-this-col-lg {
        margin-bottom: 0;
        margin-top: auto;
    }
    
}


@media (max-width: 600px) {
    
    .container-about-us {
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .about-text-container {
        padding: 2rem 5px;
    }
    
}
.contact-us-section {
    background-position: center;
    background-size: cover;
    min-height: 30vh;
}


.section-contact-text-box {
    text-align: center;
/*    font-size: 2.5rem;*/
    padding: 1rem;
    padding-top:30px;
    padding-bottom: 2.5rem;
}
section.section-padding {
    padding-left: 40px;
    padding-right: 40px;
}

.work-image-col {
    background-position: center;
    background-size: cover;
}


div.info-max-width-text-box {
    max-width: 700px;
    display: block;
    margin: auto;
    padding: 20px 20px 0 20px;
    color: #fff;
}

div.section-half-image {
    min-height: 450px;
}

.page-info-header {
    background-position: center;
    background-size: cover;
    height: 50vh;
    width: auto;
}


.info-text-box {
    display:block;
    padding: 0px 15px;
    position: relative;
    max-width: 1200px;
    text-align: left;
}

.info-text-box p,
.info-text-box h2 {
    line-height: 1.5;
}

.info-text-box h2 {
    padding-top: 20px;
    margin-bottom: 8px;
    font-size: 1.2rem;
}


section.bgImageSection {
    min-height: 50vh;
    background-position: center;
    background-size: cover;
}


@media (max-width: 992px) {
    
    section.section-padding {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 768px) {
    .info-text-box h2 {
        font-size: 1.1rem;
    }
}



.info-small-text-container {
    padding: 20px;
    padding-top: 60px;
    text-align: center;
    color: #fff;
    font-size: 1.2rem;
    max-width: 1000px;
}

.button-small-light {
    display: block;
    margin: auto;
    border: none;
    text-transform: uppercase;
    font-size: 1.06rem;
    width: 200px;
    height: 44px;
    padding-top: 10px;
    border-radius: 2px;
    
    background-color: rgba(255,255,255,0.85);
    color: #000;
    
}

.button-small-light:hover {
    cursor: pointer;
    background-color: rgba(255,255,255,1);
    color: #930000;
    text-decoration: none;
}

.button-small-light:focus {
    outline: none;
}

.centerAlign {
    text-align: center;
}

h3.centerAlign {
    padding-top: 20px;
}

p.centerAlign {
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 20px;
    max-width: 400px;
    display: block;
    margin: auto;
}
.vertical-menu {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    width: 100%;
}

.vertical-menu a {
    background-color: none;
    color: #000;
    display: block;
    padding: 15px 20px;
    text-decoration: none;
    border-bottom: 1px solid #000;
    font-size: 1.2rem;
}


.vertical-menu a.last {
    border: none;
}

.vertical-menu a.active {
    color: #8d0000;
}

.vertical-menu a:hover {
    color: rgba(0,0,0,0.5);
}

.vertical-menu a {
    font-size: 1.1rem;
    padding: 30px 15px;
}

.collapseMenuContainer {
    padding: 0 20px 14px 20px;
}

.collapseHeader {
    padding: 20px;
}

.collapseHeader:hover {
    cursor: pointer;
}

.menu-mobile {
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 0;
    margin: 16px 0 8px 0;
}

.menu-mobile h4 {
    margin: 0;
    color: #8d0000;
}

.menu-mobile a {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    text-decoration: none;
    color: #000;
    font-size: 1.1rem;
}

.menu-mobile a:hover {
    color: rgba(0,0,0,0.5);
}
.side-menu-container {
    padding-right: 0;
    padding-left: 0;
    min-width: 320px;
}

@media (max-width: 1300px) {
    .side-menu-container {
        min-width: 260px;
    }
}


.topCategoryTitle {
    padding: 80px 0px 0px 32px;
 }
.dialog--background {
   z-index: 999;
   position: fixed;
   top: 0;
   left: 0;
   background-color: rgba(0, 0, 0, 0.39);
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.dialog--box {
   background-color: white;
   padding: 32px;
   border-radius: 8px;
}

.dialog--box--buttons {
   display: flex;
   justify-content: flex-end;
}

.dialog--button {
   margin-left: 8px;
   outline: none;
   border: none;
   border-radius: 0.25rem;
   padding: 8px 20px;
   color: white;
   transition: 0.2s ease;
}

.dialog--button__red {
   background-color: #DC3644;
}

.dialog--button__red:hover {
   background-color: #b82d39;
}

.dialog--button__green {
   background-color: #28A744;
}

.dialog--button__green:hover {
   background-color: #1f8535;
}

.dialog--button:disabled {
   background-color: rgb(168, 168, 168);
}


div.project-list-item {
    padding: 12px 32px;
    width: 100%;
    max-width: 1000px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background: rgba(254,254,253, 1);
    border-radius: 3px;
    margin: 26px 0px;
}

.p-main-icon {
    width: 192px;
    height: 192px;
    background-repeat:  no-repeat;
    background-position: center;
    background-size: cover;
}

.p-list-item-info-box {
    padding-left: 32px;
    padding-top: 24px;
}

button.edit-project,
button.delete-project {
    display: inline-block;
    width: 128px;
    margin: 4px;    
}

button.new-project {
    width: 100%;
    margin: auto;
}

@media (max-width: 991px) {
    .p-main-icon {
        width: 128px;
        height: 128px;
    }
}


@media (max-width: 767px) {
    button.edit-project,
    button.delete-project {
        width: 100px;
    }

    .p-list-item-info-box {
        padding-top: 0px;
    }
}


@media (max-width: 575px) {

    .p-list-item-info-box {
        margin: auto;
    }

    .p-main-icon {
        margin: auto;
        margin-bottom: 32px;
        width: 80%;
        height: 128px;
    }

    div.project-list-item {
        padding: 12px;
    }

}



.projecItemsCotainer {
   padding: 0px 0px 40px 32px;
}


@media (max-width: 991px) {
   .projecItemsCotainer {
      padding: 16px 16px 40px 16px;
   }
}

@media (max-width: 575px) {
   .projecItemsCotainer {
      padding: 16px 0px 40px 0px;
   }
}

.project-order-save-button {
   /* position: fixed; */
   bottom: 0;
   right: 0;
   /* margin: 30px 40px; */
   padding: 8px 20px;
   color: white;
   background-color: #28A744;
   border: none;
   border-radius: 8px;
   transition: 0.2s ease;
   /* height: 50px; */
   margin-left: 12px;
}

.project-order-save-button:hover {
   background-color: #208135;
}

.project-order-save-button:disabled {
   background-color: #919191;
}

.project-order-cancel-button {
   bottom: 0;
   right: 0;
   padding: 8px 20px;
   color: white;
   background-color: #DC3644;
   border: none;
   border-radius: 8px;
   transition: 0.2s ease;
   margin-left: 12px;
}

.project-order-cancel-button:hover {
   background-color: #b42c38;
}

.project-order-cancel-button:disabled {
   background-color: #919191;
}

.reorder-toolbar {
   border-top: 1px solid gray;
   background-color: rgba(255, 255, 255, 0.815);
   position: fixed;
   display: flex;
   justify-content: space-between;
   align-items: center;
   bottom: 0;
   left: 0;
   text-align: right;
   height: 80px;
   width: 100%;
}

.reorder-toolbar p {
   margin: 0;
   margin-left: 32px;
}


div.project-item {
    padding-top: 20px;
    max-width: 1200px;
    padding-bottom: 4rem;
}

.p-main-img {
    display: block;
    margin: auto;
    background-repeat:  no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: calc(40vw + 10vh);
    max-height: 720px;
}


.p-info-box {
    padding: 30px 15px;
}

.p-info-box .title h3 {
    margin: 0;
}
.p-info-box .title {
    display:inline-block;
    margin-bottom: 8px;
}

.subtitle {
    font-weight: lighter;
    font-size: 1.15rem;
    padding: 8px 0;
    margin-bottom: 16px;
    font-weight: 100;
}

.subtitle div,
.subtitle p,
.subtitle p b {
    margin: 0;
    padding: 0;
    display:inline-block;
}

.subtitle:not(div) p,
.subtitle p b {
    white-space: pre;
}



.subtitle p b {
    color: #8d0000;
    font-size: 1.2rem;
}

p.description {
    padding-top: 20px;
}


button.tallenna-btn,
button.takaisin-btn {
    width: 128px;
    margin: 4px;
}


@media (max-width: 992px) {

    .p-info-box {
        padding: 30px 12px;
    }

}

@media (max-width: 575px) {

    .project-item {
        padding: 4px;
    }

    .p-img-container {
        padding: 4px;
    }
}

#signInForm {
    margin-top: 256px;
    vertical-align: middle;
    background: #fcfcfc;
    padding: 96px 80px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 2px;
}

#signInBtn {
    margin-top: 24px;
}

#signInTitle {
    margin-bottom: 32px;
}

.signInInput {
    margin-bottom: 8px;
}

#forgotPasswordLink {
    margin-left: 6px;
    font-size: 0.85rem;
}

.warningText {
    margin-left: 6px;
    margin-bottom: 0;
}



.invalidInput {
    border-color: rgb(255, 104, 104);
}
.invalidInput:focus,
.invalidInput:active {
    box-shadow: 0px 0px 0px 3px rgb(255, 163, 153);
    border-color: rgb(255, 90, 90);
}
.invalidInput::-webkit-input-placeholder {
    color: rgb(204, 98, 98) !important;
}
.invalidInput::placeholder {
    color: rgb(204, 98, 98) !important;
}
.passwordInput {
    margin-bottom: 8px;
}

.warningText {
    margin-left: 6px;
    margin-bottom: 0;
}
