
.main-header {
    background-position: center;
    background-size: cover;
    height: 100vh;
    width: auto;
    position: relative;
    overflow: hidden;
}

span.nav-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
}


.header-dark-layer {
    height: 100%;
    background-color: black;
    opacity: 0.35;
}



.header-title-container {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    color: #fff;
    pointer-events: none;
}

.center-header-title-container {
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.center-header-title-container h2 {
    font-size: 6rem;
    padding: 0 40px;
    text-transform: uppercase;
    line-height: 7rem;
    max-width: 1800px;
}

@media (max-width: 1800px) {
    .center-header-title-container h2 {
        font-size: 5rem;
        line-height: 6rem;
    }
}


@media (max-width: 992px) {
    .center-header-title-container h2 {
        font-size: 4rem;
        line-height: 4.5rem;
    }
    .main-header {
        height: calc(100vh - 59px);
    }
    
    .center-header-title-container h2 {
        padding-bottom: 59px;
    }
    
}

@media (max-width: 768px) {
    .center-header-title-container h2 {
        font-size: 3.2rem;
        line-height: 3.5rem;
    }
}


@media (max-width: 576px) {
    .center-header-title-container h2 {
        font-size: 2.15rem;
        line-height: 2.5rem;
    }
}