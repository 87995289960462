section.section-padding {
    padding-left: 40px;
    padding-right: 40px;
}

.work-image-col {
    background-position: center;
    background-size: cover;
}


div.info-max-width-text-box {
    max-width: 700px;
    display: block;
    margin: auto;
    padding: 20px 20px 0 20px;
    color: #fff;
}

div.section-half-image {
    min-height: 450px;
}

.page-info-header {
    background-position: center;
    background-size: cover;
    height: 50vh;
    width: auto;
}


.info-text-box {
    display:block;
    padding: 0px 15px;
    position: relative;
    max-width: 1200px;
    text-align: left;
}

.info-text-box p,
.info-text-box h2 {
    line-height: 1.5;
}

.info-text-box h2 {
    padding-top: 20px;
    margin-bottom: 8px;
    font-size: 1.2rem;
}


section.bgImageSection {
    min-height: 50vh;
    background-position: center;
    background-size: cover;
}


@media (max-width: 992px) {
    
    section.section-padding {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 768px) {
    .info-text-box h2 {
        font-size: 1.1rem;
    }
}



.info-small-text-container {
    padding: 20px;
    padding-top: 60px;
    text-align: center;
    color: #fff;
    font-size: 1.2rem;
    max-width: 1000px;
}
