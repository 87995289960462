
.projectContainer {
    background-color: white;
    padding: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
    text-align: center;
    
    -moz-transition: all 0.7s;
    -webkit-transition: all 0.7s;
    transition: all 0.7s;
    height: 50vh;

    background-position: center;
    background-size: cover;
}

.projectContainer h4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0;
    background-color: rgba(255,255,255,0.7);
    width: 100%;
}


.projectContainer:hover, .projectContainer:focus {

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    transform: scale3d(1.02, 1.02, 1);
    cursor: pointer;
    
}


.bottom-row {
    height: 100%;
    display: flex;
}


.defaultTextView {
    margin-top: auto;
    
}




.col-project-container {
    padding: 0;
    overflow: hidden;
    position: relative;
}



.project-dark-layer {
    height: 100%;
    background-color: black;
    opacity: 0.3;
        
    -moz-transition: all 0.7s;
    -webkit-transition: all 0.7s;
    transition: all 0.7s;
}

.project-dark-layer:hover {
    height: 100%;
    background-color: black;
    opacity: 0.65
}





.project-title-container {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    color: #fff;
    pointer-events: none;
}

.center-title-container {
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.center-title-container h3 {
    font-size: 1.7rem;
    margin: 1rem;
}



@media (max-width: 1500px) {
    .center-title-container h3 {
        font-size: 1.5rem;
    }
}