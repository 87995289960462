.button-small-light {
    display: block;
    margin: auto;
    border: none;
    text-transform: uppercase;
    font-size: 1.06rem;
    width: 200px;
    height: 44px;
    padding-top: 10px;
    border-radius: 2px;
    
    background-color: rgba(255,255,255,0.85);
    color: #000;
    
}

.button-small-light:hover {
    cursor: pointer;
    background-color: rgba(255,255,255,1);
    color: #930000;
    text-decoration: none;
}

.button-small-light:focus {
    outline: none;
}
