footer {
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: #2a2a2a;
    color: white;
}

div.footer-box {
    padding-bottom: 40px;
}


footer ul {
    list-style: none;
    padding-left: 0;
}

.footer-inner-container {
    padding: 130px;
    padding-bottom: 20px;
    max-width: 1800px;
}

footer .footer-link {
    color: inherit;
    text-decoration: none;
}

footer .footer-link:hover {
    color: inherit;
    text-decoration: underline;
}

footer .footer-link:visited {
    color: inherit;
    text-decoration: none;
}

.logo-box {
    display: block;
    margin: auto;
    padding: 20px 40px;;
}

.logo-box img {
    max-width: 100%;
    display: block;
    margin: auto;
}

footer div.logo-container {
    max-width: 280px;
    padding-top: 20px;
}


.bromeco-logo-img {
    width: 104px;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 0.3;
}

.bromeco-logo-img:hover, bromeco-logo-img:focus {
    transform: scale3d(1.1, 1.1, 1);
    cursor: pointer;
    opacity: 0.5;
}

.logo-row {
    padding: 26px;
    padding-bottom: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bromeco-logo-row {
    padding: 26px;
    padding-top: 20px;
    padding-bottom: 34px;
}

.bromeco-logo-row > a {
    margin-left: auto;
    margin-right: auto;
}

.row-copyright {
  padding-top: 20px;
  font-weight: 300;
  color: #696969;
  max-width: 100vw;
  padding-left: 12px;
  padding-right: 12px;
}

.row-logo {
    margin: 20px 50px;
    max-width: 180px;
    /* height: auto; */
    max-height: 190px;

}

@media (max-width: 1200px) {
    .footer-inner-container {
        padding: 80px;
        padding-bottom: 60px;
    }
}

@media (max-width: 576px) {
    .footer-inner-container {
        padding: 50px;
        padding-top: 80px;
        padding-bottom: 15px;
    }
}