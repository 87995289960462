

.intro-jumbotron {
    padding-top: 0;
}

.intro-text-box {
    max-width: 1200px;
    padding: 20px 40px;
    text-align: center;
}

.intro-text-box p {
    font-size: 1.2rem;
}

.intro-text-box a {
    color: #930000;
}

@media (max-width: 768px) {
    .intro-text-box p {
        font-size: 1.1rem;
    }

    .intro-text-box {
        padding: 20px;
    }
}
