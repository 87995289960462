.centerAlign {
    text-align: center;
}

h3.centerAlign {
    padding-top: 20px;
}

p.centerAlign {
    padding: 5px;
    padding-top: 10px;
    padding-bottom: 20px;
    max-width: 400px;
    display: block;
    margin: auto;
}