@import url('https://fonts.googleapis.com/css?family=Lato:300,400');

html {
    overflow-x: hidden;
}

body {
    font-family: 'Lato', sans-serif;
    margin: 0;
    padding: 0;
    word-wrap: break-word;
}

section {
    padding: 6rem 0rem;
}


section.sectionGray {
    background-color: #ededed;
}

section.no-padding {
    padding: 0;
/*    background-color: #606060;*/
}

.container-dark {
    background-color: #404040;
    color: white;
}

.container-light-gray {
    background-color: #fafafa;
}

.inner-container {
    padding: 30px 16px;
}


.max-width-container {
    max-width: 1300px;
}


/*INTRO SECTION*/

.jumbotron-fluid {
    text-align: center;
    background: none;
    padding-top: 25px;
    padding-bottom: 25px;
    margin: 0;
}

hr.shortLine {
    width: 100px;
    color: black;
    background-color: black;
    opacity: 1;
    height: 1px;
    border: none;
    padding: 0;
    margin-top: 20px;
}



/*Section Projects*/


.center-this {
    display: block;
    margin: auto;
}

.v-h-center {
    display: flex;
    align-items: center;
    justify-content: center;

}

.text-align-center {
    text-align: center;
}



.PageTitle h1 {
    font-size: 4rem;
    text-transform: uppercase;
}


@media (max-width: 768px) {
    .PageTitle h1 {
        font-size: 3rem;
    }
    
    .inner-container {
        padding: 20px 0px;
    }
}

@media (max-width: 576px) {
    .PageTitle h1 {
        font-size: 2.5rem;
    }
}

@media (max-width: 370px) {
    .PageTitle h1 {
        font-size: 2.1rem;
    }
}



#red {
    background: red;
}

.appearOnScroll {
    opacity: 0;
}