.navbar {
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    -o-transition: all 0.4s ease-out;
    -ms-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    background: #fff;
    min-height: 52px;
}

.navbar.transparent {
    background: none;
}

.nav-item {
    padding-left: 1rem;
    padding-right: 1rem;
    margin: auto;
    display: block;
}


.nav-link {
    font-size: 1.2rem !important;
    color: #000;
    text-transform: uppercase;
}

.nav-link:visited {
    font-size: 1.3rem !important;
    color: #000;
}
.nav-link:hover {
    opacity: 0.6;
    font-size: 1.2rem !important;
    color: #000;
    cursor: pointer;
}

.nav-link:active {
    color: #000;
}


.navbar.transparent .nav-link {
    color: #fff;
}

.navbar.transparent .nav-link:visited {
    color: #fff;
}

.navbar.transparent .nav-link:hover {
    opacity: 0.6 !important;
    color: #fff;
}

.navbar.transparent .nav-link:active {
    color: #000;
}

ink:focus {
    outline: 0;
}


.navbar.center .navbar-inner {
    text-align: center;
}

.navbar.center .navbar-inner .nav {
    display:inline-block;
    float: none;
}

#navbarNavAltMarkup {
    float: right;
    height: 60px;
}


.navbar-logo {
    width: 200px;
    padding: 8px 0;
    height: 51px;
}

.navbar-toggler {
    padding-top: 10px;
}

.navbar-toggler:focus {
   outline: none;
}

@media (max-width: 1200px) {
    
    .nav-item {
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }
}

@media (max-width: 992px) {
    
    .navbar-logo {
        width: 150px;
        padding-top: 4px;
        padding-bottom: 4px;
    }
    
    .navbar {
        padding-right:0;
    }
    
    .navbar-nav {
        padding-top: 34px;
    }

}

@media (max-width: 768px) {

    .navbar-toggler {
        margin-right: -10px;
    }
}
