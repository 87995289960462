.vertical-menu {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    width: 100%;
}

.vertical-menu a {
    background-color: none;
    color: #000;
    display: block;
    padding: 15px 20px;
    text-decoration: none;
    border-bottom: 1px solid #000;
    font-size: 1.2rem;
}


.vertical-menu a.last {
    border: none;
}

.vertical-menu a.active {
    color: #8d0000;
}

.vertical-menu a:hover {
    color: rgba(0,0,0,0.5);
}

.vertical-menu a {
    font-size: 1.1rem;
    padding: 30px 15px;
}

.collapseMenuContainer {
    padding: 0 20px 14px 20px;
}

.collapseHeader {
    padding: 20px;
}

.collapseHeader:hover {
    cursor: pointer;
}

.menu-mobile {
    width: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 0;
    margin: 16px 0 8px 0;
}

.menu-mobile h4 {
    margin: 0;
    color: #8d0000;
}

.menu-mobile a {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    text-decoration: none;
    color: #000;
    font-size: 1.1rem;
}

.menu-mobile a:hover {
    color: rgba(0,0,0,0.5);
}