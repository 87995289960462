#signInForm {
    margin-top: 256px;
    vertical-align: middle;
    background: #fcfcfc;
    padding: 96px 80px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border-radius: 2px;
}

#signInBtn {
    margin-top: 24px;
}

#signInTitle {
    margin-bottom: 32px;
}

.signInInput {
    margin-bottom: 8px;
}

#forgotPasswordLink {
    margin-left: 6px;
    font-size: 0.85rem;
}

.warningText {
    margin-left: 6px;
    margin-bottom: 0;
}



.invalidInput {
    border-color: rgb(255, 104, 104);
}
.invalidInput:focus,
.invalidInput:active {
    box-shadow: 0px 0px 0px 3px rgb(255, 163, 153);
    border-color: rgb(255, 90, 90);
}
.invalidInput::placeholder {
    color: rgb(204, 98, 98) !important;
}