.arrow-row {
    position: absolute;
    bottom: 12px;
    width: 100%;
}

.arrow-container {
    background-color: #fff;
    height: 58px;
    width: 58px;
    border-radius: 29px;
    padding-top: 16px;
    
    -moz-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    border-bottom-color: black;
}

.down-arrow {
    
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border-style: solid;
    border-color: inherit;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
    transition: border-width 450ms ease-in-out;
}


.arrow-container:hover, .arrow-container:focus {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
    border-color: #930000;
    transform: scale3d(1.05, 1.05, 1);
    cursor: pointer;
}




@media (max-width: 576px) {
    .arrow-container {
        height: 40px;
        width: 40px;
        border-radius: 20px;
        padding-top: 11px;
    }
    
    .down-arrow {
        height: 14px;
        width: 14px;
    }

}
