$green: #17A33A;
$green-highlight: rgb(77, 168, 77);
$red: rgb(163, 23, 23);
$red-highlight: rgb(150, 65, 65);
$grey: rgb(107, 107, 107);

.staff-edit-controls {
   position: fixed;
   bottom: 50px;
   right: 50px;
   z-index: 99;

   button {
      border-radius: 40px;
      padding: 10px;
      border: none;
      outline: none;
      color: white;
      background-color: $green;
      transition: 0.2s;

      &:disabled {
         background-color: $grey;
      }

      &:hover {
         cursor: pointer;
         background-color: $green-highlight;
      }
   }

   .button-red {

      color: white;
      background-color: $red;

      &:hover {
         cursor: pointer;
         background-color: $red-highlight;
      }

      &:disabled {
         background-color: $grey;
      }
   }
}

.staff-item-controls {

   button {
      outline: none;
      border: none;
      width: 32px;
      height: 32px;
      color: white;
      border-radius: 50%;
      margin: 0 4px;

      &:hover {
         cursor: pointer;
      }
   }

   .arrow-button {
      background-color: $green;

      &:hover {
         background-color: $green-highlight;
      }

      &:disabled {
         background-color: $grey;
      }
   }

   .remove-button {
      background-color: $red;

      &:hover {
         background-color: $red-highlight;
      }

      &:disabled {
         background-color: $grey;
      }
   }
}

.avatar-div {
   background-position: 50%;
   background-size: cover;
   width: 160px;
   height: 180px;
   margin-left: auto;
   margin-right: auto;
   margin-bottom: 10px;
}

.add-new-container {
   height: 394px;
   align-self: center;
   width: (1/3) * 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   &:hover {
      cursor: pointer;
   }

   .add-new-circle {
      border-radius: 50%;
      background-color: $green;
      width: 100px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 80px;
      color: white;
      transition: 0.2s;
      margin-bottom: 16px;

      &:hover {
         background-color: $green-highlight;
      }
   }
}
